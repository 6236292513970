import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

    render() {

        const {
            className,
            topOuterDivider,
            bottomOuterDivider,
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            pushLeft,
            ...props
        } = this.props;

        const outerClasses = classNames(
            'features-tiles section center-content',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        const innerClasses = classNames(
            'features-tiles-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        const tilesClasses = classNames(
            'tiles-wrap',
            pushLeft && 'push-left'
        );

        const sectionHeader = {
            title: '',
            paragraph: ''
        };


        return (
            <section
                {...props}
                className={outerClasses}
            >
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader data={sectionHeader} className="center-content" />
                        <div className={tilesClasses}>

                            <div className="tiles-item">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item">
                                            <Image
                                                src={require('./../../assets/images/feature-tile-icon-01.svg')}
                                                alt="Features tile icon 01"
                                                width={128}
                                                height={72} />
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                                            创新的解決方案
                                        </h4>
                                        <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                                            通过对国内外医疗资源的整合，科技的赋能及健康管理生态合作伙伴联盟，为金融保险机构提供获客、增值、风控、健康险产品创新等4大解决方案，为用户提供全生命周期的一站式健康管理服务。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                                            <Image
                                                src={require('./../../assets/images/feature-tile-icon-02.svg')}
                                                alt="Features tile icon 02"
                                                width={128}
                                                height={72} />
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                                            专业的管理团队
                                        </h4>
                                        <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="300">
                                            运营总部设在上海，并在广州、北京等地设有开发及服务团队。团队成员来自于知名保险公司，互联网平台，企业管理咨询公司等，具有优秀的保险产品开发，精算定价，系统开发，运营服务管理，大数据等领域的经验。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                                            <Image
                                                src={require('./../../assets/images/feature-tile-icon-03.svg')}
                                                alt="Features tile icon 03"
                                                width={128}
                                                height={72} />
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="300">
                                            成熟的服务模式
                                        </h4>
                                        <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="400">
                                            在健康服务领域，睿贝偲及其战略合作伙伴已经拥有成熟完善的业务服务模式，并且具有一定的市场认可度及市场占有率。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item">
                                            <Image
                                                src={require('./../../assets/images/feature-tile-icon-04.svg')}
                                                alt="Features tile icon 04"
                                                width={128}
                                                height={72} />
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                                            智能健康管理服务平台
                                        </h4>
                                        <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                                            睿贝偲建立的全国性的齿科医疗服务网络，能够满足保险公司医疗服务的需求。而在保险公司和线下牙科诊所两者之间，睿贝偲以自主研发的保险医疗服务平台“易果健康服务”把他们连接到一起，用于齿科医疗保险全流程运营管理。睿贝偲致力于为保险公司提供齿科保险产品设计、精算、核保、厘算、医疗合理性监控等服务。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                                            <Image
                                                src={require('./../../assets/images/feature-tile-icon-05.svg')}
                                                alt="Features tile icon 05"
                                                width={128}
                                                height={72} />
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                                            知名合作伙伴
                                        </h4>
                                        <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="300">
                                            我们及合作伙伴目前已经与同方全球、中宏人寿、华夏人寿、富德生命人寿、安盛保险等多家大型金融保险机构达成了深度合作。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                                            <Image
                                                src={require('./../../assets/images/feature-tile-icon-06.svg')}
                                                alt="Features tile icon 06"
                                                width={128}
                                                height={72} />
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="300">
                                            出色的保险产品设计能力
                                        </h4>
                                        <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="400">
                                            通过整合优势医疗资源来为商业保险、金融机构及其他企业提供特色化的健康管理及医疗服务，并推出创新型的健康险产品。睿贝偲通过与金融保险机构合作，已经帮助个险、银保、网销等业务部门搭建高效获客体系；为健康险事业部、健康管理部、客服部等部门提供全套综合健康管理服务；为产品精算等部门提供新型保险产品开发，共创健康保险产品。
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
