import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
// import Testimonial from '../components/sections/Testimonial';
import GenericSection from '../components/sections/GenericSection';
import Image from '../components/elements/Image';

import SectionHeader from '../components/sections/partials/SectionHeader';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>

        <HeroSplit invertMobile imageFill className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <FeaturesTiles />

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data='营业执照' className="center-content" />

            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/00.png')}
                alt="Placeholder"
                width={356}
                height={200} />
            </figure>
          </div>
        </GenericSection>
        <div className="spacer-64 spacer-48-mobile"></div>
      </React.Fragment>
    );
  }
}

export default Home;
